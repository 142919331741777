<template>
  <div class="patient-info">
    <div class="patient-inner">
      <div class="person-wrapper">
        <div
          class="person-detail"
          :class="{'pt-4': activePolicies.length}"
        >
          <div class="person-status">
            <div
              v-if="patient.person.userId && !patient.person.templateId"
              :title="patient.person.userId || null"
              class="mobile-check"
            >
              <icon-check-mobile
                v-if="patient.person.userId"
              />
              <icon-not-check-mobile
                v-else
              />
            </div>

            <div
              v-if="patient.person.userId && !patient.person.templateId"
              class="is-main mb-2"
            >
              {{ isMain }}
            </div>

            <icon-gosuslugi
              v-if="patient.person.isGosuslugi"
              class="is-gosuslugi"
            />

            <icon-mango
              v-if="patient.person.isMango"
              class="is-mango"
            />
            <div
              v-if="patient.person"
              class="p-1"
            >
              <img
                :src="PROJECT_TYPES[patient.person.projectType]?.logo"
                alt="#"
                class="person-info__project-type-img rounded"
              >
            </div>
          </div>
          <div class="person-info">
            <div class="person-name">
              {{ patient.person.fullName }}
            </div>
            <div class="person-birthdate">
              {{ patient.person.birthDate ? parseData(patient.person.birthDate) : null }}
            </div>
            <div
              v-if="patient.person.comment"
              class="mt-1 text-secondary small patient-comment"
            >
              <span class="font-weight-bold">Комментарий:</span> {{ patient.person.comment }}
            </div>
          </div>
          <div style="width: 40px;margin-right: auto;">
            <span
              v-if="patient.person.isVip"
              class="status-badge status-vip mb-1"
            >
              VIP
            </span>
            <span
              v-if="patient.person.isTop"
              class="status-badge status-top mb-1"
            >
              TOP
            </span>
            <span
              v-if="patient.person.isTest"
              class="status-badge status-test"
            >
              TEST
            </span>
          </div>
          <div class="person-dop-info">
            <div class="person-phone">
              {{ patient.person.phoneNumber }}
            </div>

            <b-button
              v-if="patient.person.wasDeleted"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="button-return-patient px-3"
              @click="openReestablishModal"
            >
              Восстановить
            </b-button>

            <div
              class="person-controls"
              :class="{'mt-2':patient.person.phoneNumber}"
            >
              <b-button
                v-if="isMainPatient && checkFeatureAccess({
                  name: 'Возможность вызова пациента',
                  url: '/patients',
                })"
                v-b-tooltip.hover.bottomleft.v-light
                variant="light"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="crm-border call"
                title="Вызов пациента"
                @click="makeCall"
              >
                <b-icon
                  icon="telephone"
                  style="color: #4B62C1;"
                />
              </b-button>

              <icon-message
                v-if="patient.person.phoneNumber && checkFeatureAccess({
                  name: 'Возможность написать сообщение',
                  url: '/patients',
                })"
                class="crm-control-icon crm-border"
                @click="openSmsSendModal"
              />
              <icon-buble-user
                class="crm-control-icon crm-border"
                @click="openPatientDetail"
              />
              <icon-clouds
                v-if="checkFeatureAccess({
                  name: 'Возможность создать обращение',
                  url: '/patients',
                })"
                class="crm-control-icon clouds-icon crm-border"
                @click="openAppealEditor"
              />
              <span
                v-if="checkFeatureAccess({
                  name: 'Возможность посмотреть устройства пациента',
                  url: '/patients',
                })"
                class="crm-control-icon crm-border crm-control-icon--custom"
                @click="openPatientDeviceModal"
              >
                <b-icon
                  icon="tv"
                  style="color: #4B62C1;"
                />
              </span>
            </div>
            <div class="mt-2">
              <b-button
                v-if="patient.person.userId && canAuthorize"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="mt-2"
                @click="openPatientAuth"
              >
                Авторизоваться
              </b-button>
            </div>
          </div>
        </div>

        <div class="person-policies">
          <div
            v-if="activePolicies.length === 0 && pendingPolicies.length === 0"
            class="policies-not-found"
          >
            Нет полисов
          </div>

          <Loading
            v-if="isActive"
            class="crm-loading-wrapper"
          >
            Загрузка полисов
          </Loading>

          <div
            v-for="(policy, index) in activePolicies"
            :key="policy.policyId + index"
            class="person-policy active-policy"
          >
            <slot
              :id="policy.id"
              name="policy-header"
            />

            <div class="d-flex p-3">
              <div class="mr-auto policy-info-wrapper d-flex">
                <div class="policy-name">
                  <template>
                    <template v-if="policy.companyName">
                      {{ policy.companyName }}. <br>
                    </template>
                    <template v-if="policy.programName">
                      {{ policy.programName }}. <br>
                    </template>
                    {{ policy.insuranceName }}
                  </template>
                </div>
                <div
                  v-if="policy.isTest"
                  class="mt-1"
                >
                  <span class="policy-test">ТЕСТ</span>
                </div>
                <div class="policy-fullname mt-2">
                  {{ policy.lastName }} {{ policy.firstName }} {{ policy.middleName }}
                </div>
                <div class="policy-ensurerName mt-1">
                  {{ policy.ensurerName }}
                </div>
                <div class="policy-id mr-4">
                  № {{ policy.policyId }}
                </div>
                <div
                  v-if="policy.startDate && policy.endDate"
                  class="policy-date mt-1"
                >
                  <span id="start-date">{{ parseData(policy.startDate) }}</span>
                  -
                  <span id="end-date">{{ parseData(policy.endDate) }}</span>
                </div>
                <div
                  v-if="policy?.limitResult"
                  class="policy-date mt-1"
                >
                  Оставшиеся консультации: {{ policy?.limitResult?.totalCountSpecializationLimit }}
                </div>
                <div
                  v-if="!policy.startDate && policy.endDate"
                  class="policy-date mt-1"
                >
                  до {{ parseData(policy.endDate) }}
                </div>
                <div
                  v-if="policy.prolongation"
                  class="mt-1"
                >
                  Пролонгирован
                </div>
                <div
                  v-if="policy.isRelateActive"
                  class="mt-1"
                >
                  Активирован
                </div>
                <div
                  v-if="policy.isConsentToAnalysis"
                  class="mt-1"
                >
                  <span class="available-analysis">Анализы доступны</span>
                </div>
              </div>
              <div class="ml-2 mr-1">
                <b-button
                  v-if="policy.isRelateActive === false"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  @click="onPolicyActivate(policy.id)"
                >
                  Активировать
                </b-button>
              </div>
              <div
                v-if="isPolicyValid(policy)"
                class="policy-controls"
              >
                <b-button
                  v-if="showCreateGuaranteeLetter"
                  v-b-tooltip.hover.left.v-light
                  variant="light"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="border p-0"
                  title="Создать ГП"
                  :disabled="policy.isRelateActive === false"
                  @click="openIndemnityModal(policy.id, policy.subProgramV2Id)"
                >
                  <icon-worksheet />
                </b-button>
                <b-button
                  v-if="showCreateDirection"
                  v-b-tooltip.hover.left.v-light
                  variant="light"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="mt-2 border p-1"
                  :disabled="policy.isRelateActive === false"
                  title="Создать направление"
                  @click="openIndemnityModal(policy.id, policy.subProgramV2Id, true)"
                >
                  <b-icon
                    icon="file-earmark-medical"
                    class="text-info"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover.left.v-light
                  variant="light"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="mt-2 p-1 border"
                  title="Открыть лимиты полиса"
                  @click="openPolicyLimitsModal(policy.id)"
                >
                  <b-icon
                    icon="list-ul"
                    class="text-info"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover.left.v-light
                  variant="light"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="mt-2 border p-0"
                  title="Детальная информация"
                  @click="openPolicyDetail(policy.id)"
                >
                  <icon-info />
                </b-button>
              </div>

              <div
                v-else
                class="not-sent"
              >
                Данный полис не был прислан страховой компанией
              </div>
            </div>
          </div>

          <div
            v-for="(policy, index) in pendingPolicies"
            :key="policy.policyId + index"
            class="person-policy pending-policy"
          >
            <div class="mr-auto policy-info-wrapper">
              <div class="policy-name">
                <template>
                  <template v-if="policy.companyName">
                    {{ policy.companyName + ". " }}
                  </template>
                  <template v-if="policy.programName">
                    {{ policy.programName + ". " }}
                  </template>
                  {{ policy.insuranceName }}
                </template>
              </div>
              <div
                v-if="policy.isTest"
                class="mt-1"
              >
                <span class="policy-test">ТЕСТ</span>
              </div>
              <div class="policy-fullname mt-2">
                {{ policy.lastName }} {{ policy.firstName }} {{ policy.middleName }}
              </div>
              <div class="policy-ensurerName mt-1">
                {{ policy.ensurerName }}
              </div>
              <div class="d-flex mt-1 flex-wrap">
                <div class="policy-id mr-4">
                  № {{ policy.policyId }}
                </div>
                <div
                  v-if="policy.startDate && policy.endDate"
                  class="policy-date"
                >
                  <span id="start-date">{{ parseData(policy.startDate) }}</span>
                  -
                  <span id="end-date">{{ parseData(policy.endDate) }}</span>
                </div>
                <div
                  v-if="!policy.startDate && policy.endDate"
                  class="policy-date"
                >
                  до {{ parseData(policy.endDate) }}
                </div>
              </div>
              <div
                v-if="policy.prolongation"
                class="mt-1"
              >
                Пролонгирован
              </div>
            </div>
            <div
              class="policy-controls"
            >
              <b-button
                v-if="showCreateGuaranteeLetter"
                variant="light"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="border p-0"
                title="Создать ГП"
                @click="openIndemnityModal(policy.id, policy.subProgramV2Id)"
              >
                <icon-worksheet />
              </b-button>
              <b-button
                v-if="showCreateDirection"
                variant="light"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="border mt-2 p-1"
                title="Создать направление"
                @click="openIndemnityModal(policy.id, policy.subProgramV2Id, true)"
              >
                <b-icon
                  icon="file-earmark-medical"
                  class="text-info"
                />
              </b-button>
              <b-button
                variant="light"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="mt-2 p-1 border"
                title="Открыть лимиты полиса"
                @click="openPolicyLimitsModal(policy.id)"
              >
                <b-icon
                  icon="list-ul"
                  class="text-info"
                />
              </b-button>
              <b-button
                v-if="policy && checkFeatureAccess({
                  name: 'Возможность активировать полис пациента',
                  url: '/patients',
                })"
                variant="light"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="border mt-2 p-0"
                title="Активировать"
                @click="openPolicyActivateModal(policy)"
              >
                <icon-check />
              </b-button>
              <b-button
                variant="light"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="border mt-2 p-0"
                title="Детальная информация"
                @click="openPolicyDetail(policy.id)"
              >
                <icon-info />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="inactivePoliciesShow"
        class="inactive-policies"
      >
        <div class="redline" />
        <div class="redline-text">
          Неактивные полисы
        </div>
        <div
          v-for="(policy, index) in inactivePolicies"
          :key="policy.policyId + index"
          class="person-policy inactive-policy d-flex p-2"
        >
          <div class="mr-auto policy-info-wrapper">
            <div class="policy-name">
              <template>
                <template v-if="policy.companyName">
                  {{ policy.companyName + ". " }}
                </template>
                <template v-if="policy.programName">
                  {{ policy.programName + ". " }}
                </template>
                {{ policy.insuranceName }}
              </template>
            </div>
            <div
              v-if="policy.isTest"
              class="mt-1"
            >
              <span class="policy-test">ТЕСТ</span>
            </div>
            <div class="policy-fullname mt-2">
              {{ policy.lastName }} {{ policy.firstName }} {{ policy.middleName }}
            </div>
            <div class="policy-ensurerName mt-1">
              {{ policy.ensurerName }}
            </div>
            <div class="d-flex mt-1 flex-wrap">
              <div class="policy-id mr-4">
                № {{ policy.policyId }}
              </div>
              <div
                v-if="policy.startDate && policy.endDate"
                class="policy-date"
              >
                <span id="start-date">{{ parseData(policy.startDate) }}</span>
                -
                <span id="end-date">{{ parseData(policy.endDate) }}</span>
              </div>
              <div
                v-if="!policy.startDate && policy.endDate"
                class="policy-date"
              >
                до {{ parseData(policy.endDate) }}
              </div>
            </div>
            <div
              v-if="policy.prolongation"
              class="mt-1"
            >
              Пролонгирован
            </div>
          </div>
          <div class="policy-controls">
            <!--            <b-button-->
            <!--              v-if="showCreateGuaranteeLetter"-->
            <!--              variant="light"-->
            <!--              size="sm"-->
            <!--              class="border p-0"-->
            <!--              title="Создать ГП"-->
            <!--              @click="openIndemnityModal(policy.id, policy.subProgramV2Id)"-->
            <!--            >-->
            <!--              <icon-worksheet />-->
            <!--            </b-button>-->
            <!--            <b-button-->
            <!--              v-if="showCreateDirection"-->
            <!--              variant="light"-->
            <!--              size="sm"-->
            <!--              class="border mt-2 p-1"-->
            <!--              title="Создать направление"-->
            <!--              @click="openIndemnityModal(policy.id, policy.subProgramV2Id, true)"-->
            <!--            >-->
            <!--              <b-icon-->
            <!--                icon="file-earmark-medical"-->
            <!--                class="text-info"-->
            <!--              />-->
            <!--            </b-button>-->
            <b-button
              variant="light"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="border mt-2 p-0"
              title="Детальная информация"
              @click="openPolicyDetail(policy.id)"
            >
              <icon-info />
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="inactivePolicies.length"
      class="inactive-policies-control"
      :class="{ active: inactivePoliciesShow }"
      @click="() => inactivePoliciesShow = !inactivePoliciesShow"
    >
      <icon-arrow-down class="crm-icon-arrow-down" />
    </div>
    <div
      v-if="patient.person.wasDeleted"
      class="deleted-overlay"
    />
  </div>
</template>

<script>
import Loading from '@/components/Loading';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import {
  parseISO, format,
} from '@evd3v/date-fns';

import IconCheckMobile from 'assets/images/mobile-check.svg';
import IconNotCheckMobile from 'assets/images/mobile-not-check.svg';
import IconArrowDown from 'assets/images/arrow-down.svg';
import IconBubleUser from 'assets/images/user-with-buble.svg';
import IconMessage from 'assets/images/message.svg';
import IconWorksheet from 'assets/images/worksheet.svg';
import IconInfo from 'assets/images/info.svg';
import IconCheck from 'assets/images/check.svg';
import IconMango from 'assets/images/icon_mango.svg';
import IconGosuslugi from 'assets/images/icon_gosuslugi.svg';
import IconClouds from 'assets/images/clouds-icon.svg';
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import { showCustomMessage } from '@/helpers/messages';
import { PROJECT_TYPES, ARR_LIST_ID_FOR_CERTAIN_POLIC } from '@/helpers/consts';

import { APP_PATIENT_URL } from '@/app.config';

export default {
  name: 'PatientInfo',
  components: {
    IconCheckMobile,
    IconNotCheckMobile,
    IconArrowDown,
    IconBubleUser,
    IconMessage,
    IconInfo,
    IconWorksheet,
    IconCheck,
    IconMango,
    IconGosuslugi,
    IconClouds,
    Loading,
  },
  mixins: [mixinRoles],
  props: {
    patient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PROJECT_TYPES,
      inactivePoliciesShow: false,
      activePolicies: [],
      policyStatuses: {
        inactive: [0, 1, 4, 5],
        pending: [2],
        active: [3],
      },
      isActive: false,
    };
  },
  computed: {
    showCreateGuaranteeLetter() {
      return this.checkFeatureAccess({ name: 'Возможность создать ГП', url: '/patients' });
    },
    showCreateDirection() {
      return this.checkFeatureAccess({ name: 'Возможность создать направление', url: '/patients' });
    },
    canAuthorize() {
      return this.$store.state.Auth.user.canAuthorize;
    },
    endDateExpired() {
      const value = new Date() > new Date(this.promocode.endDate);
      return value;
    },
    isActivePolicies() {
      const array = this.patient.policies.filter((policy) => this.policyStatuses.active.includes(policy.policyStatus));
      return array;
    },
    inactivePolicies() {
      const array = this.patient.policies.filter((policy) => this.policyStatuses.inactive.includes(policy.policyStatus));
      return array;
    },
    pendingPolicies() {
      const array = this.patient.policies.filter((policy) => this.policyStatuses.pending.includes(policy.policyStatus));
      return array;
    },
    isMain() {
      const { parentId, isMain } = this.patient.person;

      // only Gostelemed user may have parentId
      if (parentId) {
        return 'доп';
      }

      if (isMain) {
        return 'осн';
      }

      return 'доп';
    },
    isMainPatient() {
      return this.patient.person.isMain && this.patient.person.phoneNumber && this.patient.person.projectType === 0;
    },
  },
  async created() {
    await this.processActivePolicies();
  },
  mounted() {
    setTimeout(() => {
      this.calculateData();
    }, 100); // Выполнить после обновления DOM
  },
  methods: {
    async processActivePolicies() {
      try {
        this.isActive = true;
        const promises = this.isActivePolicies.map(async (policy) => {
          let limitResult = null;

          if (policy?.subprogram.sharedLimits) {
            limitResult = await this.limitConsultations(policy);
          }
          return { ...policy, limitResult };
        });

        this.activePolicies = await Promise.all(promises);
      } catch (err) {
        console.log('error with processActivePolicies', err);
      } finally {
        this.isActive = false;
      }
    },
    limitConsultations(policy) {
      return this.$store.dispatch(this.$types.PATIENT_LIMITS_BY_PERSON_ID_FETCH, {
        personId: this.patient?.person?.id,
        policyId: policy?.id,
      });
    },
    onPolicyActivate(policyId) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            title: 'Активировать полис?',
            cb: () => this.activatePolicy(policyId),
          },
        },
      );
    },
    async activatePolicy(policyId) {
      await this.$store.dispatch(this.$types.POLICY_RELATE_ACTIVATE, policyId);
      Bus.$emit('patients:update');
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    calculateData() {
      const twoMonthsAhead = new Date();
      twoMonthsAhead.setMonth(twoMonthsAhead.getMonth() + 2);
      const dateElements = document.querySelectorAll('[id="end-date"]');
      dateElements.forEach((el) => {
        const dateParts = el.innerHTML.split('.'); // Разбиваем строку на массив частей
        const year = parseInt(dateParts[2], 10); // Получаем год
        const month = parseInt(dateParts[1], 10) - 1; // Получаем месяц ( с 0)
        const day = parseInt(dateParts[0], 10); // Получаем день
        const targetDate = new Date(year, month, day); // Создаем объект Date
        if (targetDate < twoMonthsAhead) {
          el.style.color = 'red';
          el.style.fontWeight = 'bold';
        }
      });
    },
    isPolicyValid(policy) {
      if (ARR_LIST_ID_FOR_CERTAIN_POLIC.includes(policy.subProgramV2Id)) {
        return (
          policy.companyName && policy.policyId && policy.startDate && !!policy.lastName
        );
      }
      return true;
    },
    openSmsSendModal() {
      const { firstName, middleName } = this.patient.person;
      const middleNameString = middleName ? ` ${middleName}` : '';

      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsSendModal',
        props: {
          numberProp: this.patient.person.phoneNumber,
          name: `${firstName || ''}${middleNameString}`,
        },
      });
    },
    openPatientDetail() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDetailModal',
        props: {
          patientId: this.patient.person.id,
          patientInfo: this.patient,
        },
      });
    },
    openAppealEditor() {
      uiService.showModal(MODALS.APPEAL_EDITOR_NEW_MODAL, {
        name: 'AppealEditorModalNew',
        props: {
          personId: this.patient.person?.id,
          creatingFromPatient: true,
        },
      });
    },
    openIndemnityModal(policyId, subProgramId, isReferral = false) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityModal',
        props: {
          personId: this.patient.person.id,
          policyId,
          subProgramId,
          isReferral,
        },
      });
    },
    openPolicyDetail(policyId) {
      this.$store.commit(this.$types.PATIENT_ACTIVE_POLICY_ID_SET, policyId);
      this.openPatientDetail();
    },
    openPolicyActivateModal(policy) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PolicyActivateModal',
        props: {
          policy,
        },
      });
    },
    openReestablishModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientReestablishModal',
        props: {
          id: this.patient.person.id,
        },
      });
    },
    openPatientDeviceModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDeviceModal',
        props: {
          personId: this.patient.person.id,
          fio: this.patient.person.fullName,
        },
      });
    },
    async openPatientAuth() {
      try {
        const { token, refreshToken } = await this.$store.dispatch(this.$types.PATIENT_AUTH_TOKEN_FETCH, this.patient.person.id);
        const patientUrl = APP_PATIENT_URL;
        const url = `${patientUrl}?access_token=${token}&refreshToken=${refreshToken}`;
        console.log(url);

        window.open(url, '_blank');
      } catch (error) {
        showCustomMessage('error', 'Ошибка', 'Не удалось авторизовать пациента');
        console.error(error);
      }
    },
    openPolicyLimitsModal(policyId) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PolicyLimitsModal',
        props: {
          personId: this.patient.person.id,
          policyId,
        },
      });
    },
    async makeCall() {
      console.log(this.$sipService);
      const {
        userId: id,
        firstName,
        lastName,
        middleName,
        fullName,
        phoneNumber,
      } = this.patient.person;

      await this.$sipService.makeCall(
        phoneNumber,
        {
          callerId: this.$store.state.Auth.user.userId,
          callType: 'SIP',
          members: [{
            id,
            firstName,
            lastName,
            middleName: middleName || '',
            fullName,
            type: 'Patient',
          }],
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>

.patient-info {
  display: flex;
  width: 100%;
  background: #F9F9F9;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px $gray-shadow;
  color: #323232;
  margin-bottom: 10px;
  position: relative;
}

.patient-inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
}

.person-wrapper {
  // padding: 10px 0;
  display: flex;
}

.person-detail{
  display: flex;
  align-items: start;
  padding: 10px 0;
  width: 500px;
  min-height: 90px;
  overflow: hidden;
}

.person-status {
  margin-left: 15px;
  min-width: 30px;
  text-align: center;

  .is-main {
    font-size: 12px;
    line-height: 16px;
    color: #000;
  }

  .is-gosuslugi {
    margin-top: 5px;
  }

  .is-mango {
    margin-top: 5px;
  }
}

.person-info {
  margin-left: 15px;
  max-width: 85%;

  &__project-type-img {
    width: 30px;
  }
}

.person-dop-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 15px;
  height: 100%;
  word-break: break-all;
  text-align: right;
}

.person-phone {
  font-size: 16px;
}

.person-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
  width: 200px;
}

.person-controls {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.crm-control-icon {
  cursor: pointer;

  &.crm-border {
    outline: 1px solid #E0E0E0;
    border-radius: 5px;
    flex-shrink: 0;
  }

  &--custom {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.call{
  width: 30px;
  height: 30px;
  outline: 1px solid #E0E0E0;
  border-radius: 5px;
  flex-shrink: 0;
  padding: inherit;
    &:hover{
      background: none;
  }
}
.policy-controls .crm-control-icon:not(:last-child) {
  margin-bottom: 5px;
  margin-right: 0;
}

.clouds-icon {
  padding: 6px 5px 6px 5px;
  path {
    fill: $blue-dark;
  }
}

.policy-controls {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.person-policies {
  border-left: 1px solid #E0E0E0;
  align-self: stretch;
  padding: 5px 5px 5px 15px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: stretch;
}

.policies-not-found {
  font-size: 16px;
  line-height: 20px;
  color: #908F8D;
  align-self: center;
}

.person-policy {
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 100px;
  align-items: center;

  &.active-policy {
    border: 1px solid #219653;
  }
  &.pending-policy {
    border: 1px solid #F2994A;
  }
  &.inactive-policy {
    border: 1px solid #EB5757;
  }

  & + & {
    margin-top: 5px;
  }
}

.policy-name {
  word-break: break-word;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.inactive-policies {
  padding: 25px 10px 10px 25px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.redline {
  position: absolute;
  top: 10px;
  right: 0;
  left: 30px;
  background-color: #EB5757;
  height: 0.5px;

  &-text {
    position: absolute;
    top: 10px;
    left: 30px;
    font-size: 16px;
    line-height: 20px;
    transform: translateY(-50%);
    color: #EB5757;
    background-color: #F9F9F9;
    padding-right: 30px;
  }
}

.inactive-policies-control {
  display: flex;
  align-items: center;
  border-left: 1px solid #E0E0E0;
  cursor: pointer;
  transition: .3s;
}

.crm-icon-arrow-down {
  transition: .3s;
}

.inactive-policies-control.active {

  .crm-icon-arrow-down {
    transform: rotate(180deg);
  }
}

.deleted-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  opacity: 0.7;
  border-radius: 4px;
  z-index: 1;
}

.button-return-patient {
  margin-top: 5px;
  position: relative;
  z-index: 2;
}

.policy-info-wrapper {
  flex-direction: column;
  .available-analysis{
    border: 1px solid #219653;
    border-radius: 3px;
    padding: 1px 3px;
  }
}

.policy-test {
  font-weight: 600;
  color: red;
}

.patient-comment {
  word-break: break-all;
}

.status-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  height: 23px;
  border: 1px solid #dc3545;
  font-size: 14px;
  color: #dc3545;
}
.not-sent {
  color: $cell-dangerous;
  font-size: 13px;
  text-align: center;
  margin: auto 20px;
}
</style>
